/**
 * @desc js bridge for kewl
 */
import updateLocation2 from './location2';
import Base64 from './base64';
import packageDetails from './kewl/packageDetails';
import { json2url } from './utility';
import {
  UA,
  matchUA,
  IsAndroid,
  IsIPad,
  IsIPhone,
  IsIOS,
  IsNewIosApp,
  IsKEWLApp,
  IsAndroidApp
} from './uaInfo';
import UserInfoSingleton from './userInfoSingleton';
import alias from './alias';

const ROOT = window;
const DOC = ROOT.document;

// ios version
// const matchUA = UA.match(/live\.me-iOS\/(.+)/i) || (window.LMUserAgent && window.LMUserAgent.match(/live\.me-iOS\/(.+)/i));

// IosPostMessage = IsNewIosApp ? ROOT.webkit.messageHandlers.webViewApp.postMessage : null,

const KEWLObj = IsKEWLApp && typeof ROOT.android === 'object' ? ROOT.android : null;

// scheme协议相关
const { packageName } = packageDetails;
const { packageArea } = packageDetails;
const { packageInfo } = packageDetails;

const PREFIX = `${packageName}://`;
console.log(packageName, packageArea);

// bridge回调
function asyncCallbackName(callback, self) {
  const fnName = `${packageName
    + new Date().getTime()
  }${Math.random().
    toString().
    substr(2)}`;
  ROOT[fnName] = (data) => {
    let obj = '';
    if (typeof data === 'string') {
      try {
        obj = JSON.parse(data);
      } catch (error) {
        obj = data;
      }
    } else {
      obj = data;
    }
    // let obj = typeof data === 'string' ? JSON.parse(data) : data
    let result;
    if (callback) {
      if (self) {
        result = callback.call(self, obj);
      } else {
        result = callback(obj);
      }
    }
    // 根据 回调函数 返回值决定是否删除当前挂载函数
    if (!result) {
      delete ROOT[fnName];
    }
  };
  return fnName;
}

function getVerCode() {
  if (
    IsKEWLApp
    && !IsIOS
    && typeof KEWLObj === 'object'
    && Object.prototype.hasOwnProperty.call(KEWLObj, 'getVerCode')
  ) {
    return KEWLObj.getVerCode('');
  } if (IsKEWLApp && IsIOS) {
    if (!!matchUA && matchUA.length > 1) {
      const vers = matchUA[1].split('.');
      let num = 0;
      // eslint-disable-next-line radix
      num += vers[0] ? parseInt(vers[0]) * 10000000 : 0;
      // eslint-disable-next-line radix
      num += vers[1] ? parseInt(vers[1]) * 100000 : 0;
      // eslint-disable-next-line radix
      num += vers[2] ? parseInt(vers[2]) * 10000 : 0;
      // eslint-disable-next-line radix
      num += vers[3] ? parseInt(vers[3]) : 0;
      return num;
      // return matchUA[1];  // 3.8.65
    }
  }
  return 0;
}

function getVerName() {
  if (
    IsKEWLApp
    && !IsIOS
    && typeof KEWLObj === 'object'
    && Object.prototype.hasOwnProperty.call(KEWLObj, 'getVerName')
  ) {
    return KEWLObj.getVerName('');
  } if (IsKEWLApp && IsIOS) {
    if (!!matchUA && matchUA.length > 1) {
      // 美国包的ua里面版本号加了;app/us，所以得处理区分一下
      const iosMatchUA = matchUA[1].split(';');
      if (iosMatchUA.length > 1) {
        return iosMatchUA[0];
      }
      return matchUA[1];
    }
  }
  return '';
}

// 解决客户端oncloseh5game报错问题，本身对h5业务没有任何影响
// 在特定场景（如寻仙游戏接入）app会主动和h5通信处理，调用h5提供的window.xx全局方法，为避免调用方法错误，未定义做空方法赋值处理
// 方法可能新增或者修改方法名，依据错误上报进行对应空方法赋值即可！
if (!window.onCloseH5Game) {
  // 关闭游戏
  window.onCloseH5Game = function () { return false };
}
if (!window.getAudioGameBeamList) {
  // 获取玩游戏的列表（寻仙游戏接入）
  window.getAudioGameBeamList = function () { return false };
}
if (!window.endAudioGame) {
  // 主动退出游戏（寻仙游戏接入）
  window.endAudioGame = function () { return false };
}
if (!window.stopAudioMusic) {
  // 主动关闭游戏音乐（寻仙游戏接入）
  window.stopAudioMusic = function () { return false };
}
if (!window.startAudioMusic) {
  // 主动开启游戏音乐（寻仙游戏接入）
  window.startAudioMusic = function () { return false };
}


const KEWLApp = {
  kewlversion() {
    return '0.1.1';
  },
  UAInfo: {
    UA,
    IsAndroid,
    IsIPad,
    IsIPhone,
    IsIOS,
    IsNewIosApp,
    IsKEWLApp
  },
  PackageDetails: {
    packageName,
    packageArea,
    packageInfo,
    PREFIX
  },
  asyncCallbackName,
  tryOpen(url, fallbackUrl = null) {
    if (IsIOS) {
      if (!url && url !== '') {
        return;
      }
      url = PREFIX + url;
      if (IsIOS) {
        ROOT.location2.href = url;
        return;
      }
      const frame = DOC.createElement('iframe');
      frame.style.cssText = 'width:1px;height:1px;position:fixed;top:0;left:0;';
      frame.src = url;
      DOC.body.appendChild(frame);
      setTimeout(() => {
        DOC.body.removeChild(frame);
      }, 150);
    }
    this.tryIntentOpen(url, fallbackUrl);
  },
  tryIntentOpen(url, fallbackUrl = null) {
    const anchorEle = DOC.createElement('a');
    let downloadPage = `${ROOT.location2.protocol}//${ROOT.location2.host}/download/middlepage.html?${ROOT.location2.search}`;
    if (fallbackUrl) {
      downloadPage = fallbackUrl;
    }
    const anchorHref = `intent://${url
    }#Intent;scheme=${packageName
    };package=${packageArea
    };S.browser_fallback_url=${downloadPage
    };end`;
    anchorEle.style.cssText = 'width:1px;height:1px;position:fixed;top:0;left:0;';
    anchorEle.href = anchorHref;
    anchorEle.id = 'appIntent';
    DOC.body.appendChild(anchorEle);
    document.getElementById('appIntent').click();
    DOC.body.removeChild(anchorEle);
  },
  iosPostMessage(obj) {
    if (IsNewIosApp && typeof obj === 'object') {
      ROOT.webkit.messageHandlers.webViewApp.postMessage(obj);
    }
  },
  isSupportOpenNativePage() {
    if (IsAndroidApp) {
      return !!KEWLObj.openNativePage;
    }
    return window.BridgeSupportInfo ? !!window.BridgeSupportInfo.openNativePage : false;
  },
  openNativePage(pageName, scene = null, payload = {}) {
    if (!this.isSupportOpenNativePage()) {
      return false;
    }

    const data = {
      pageName,
      scene: scene || '',
      payload
    };

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openNativePage(JSON.stringify(data));
    } else if (IsNewIosApp) {
      data.type = 'openNativePage';
      this.iosPostMessage(data);
    } else {
      this.tryOpen(`openNativePage?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}`);
    }
    return true;
  },
  /**
   * 调起App
   */
  openApp() {
    if (IsKEWLApp) {
      return;
    }
    this.tryOpen('');
  },
  /*
   * @desc openPersonalTriviaPage
   * @ 打开答题个人页
   * */
  openPersonalTriviaPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openPersonalTriviaPage('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openPersonalTriviaPage'
      });
    } else {
      this.tryOpen('openPersonalTriviaPage');
    }
  },
  /**
   * @desc 调起账户绑定页
   * @param category
   * @method openBindAccountPage
   */
  openBindAccountPage(category) {
    if (this.openNativePage('bindaccountpage', null, { category })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openBindAccountPage(category);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openBindAccountPage',
        category
      });
    } else {
      this.tryOpen(`openBindAccountPage?category=${category}`);
    }
  },
  /**
   * @desc 跳转至个人群组界面，群组界面显示创建群组按钮；
   * @method openCreateFAMPage
   */
  openCreateFAMPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openCreateFAMPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openCreateFAMPage'
      });
    } else {
      this.tryOpen('openCreateFAMPage');
    }
  },
  /**
   * @desc 跳转至个人群组界面，群组列表中当前用户自己创建的群组
   * @method openCreateFAMPage
   */
  openFAMPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openFAMPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openFAMPage'
      });
    } else {
      this.tryOpen('openFAMPage');
    }
  },
  /**
   * @desc 分析功能 查看详情==》进入分析页面
   * @method openAnalysePage
   */
  openAnalysePage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openAnalysePage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openAnalysePage'
      });
    } else {
      this.tryOpen('openAnalysePage');
    }
  },
  /**
   * @desc 去申请达人主播
   * @method openApplyVAnchorPage
   */
  openApplyVAnchorPage() {
    if (this.openNativePage('applyvanchorpage')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openApplyVAnchorPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openApplyVAnchorPage'
      });
    } else {
      this.tryOpen('openApplyVAnchorPage');
    }
  },
  /**
   * @desc 去申请认证主播；
   * @method openApplySAnchorPage
   */
  openApplySAnchorPage() {
    if (this.openNativePage('applysanchorpage')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openApplySAnchorPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openApplySAnchorPage'
      });
    } else {
      this.tryOpen('openApplySAnchorPage');
    }
  },
  /**
   * @desc 调起qrcode页
   * @method openqrcodepage
   */
  openqrcodepage() {
    if (this.openNativePage('qrcodepage', null, { source: '0' })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openqrcodepage('0');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openqrcodepage'
      });
    } else {
      this.tryOpen('openqrcodepage?source=0');
    }
  },
  /**
   * @desc 调起短视频录制
   * @param tag  {string}
   */
  openShortVideoRecord(tag) {
    if (this.openNativePage('shortvideorecord', null, { tag })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openShortVideoRecord(tag);
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openShortVideoRecord',
          tag
        });
      } catch (e) { }
    } else {
      this.tryOpen(`openShortVideoRecord?tag=${tag}`);
    }
  },
  /**
   * @desc 跳转活动中心
   */
  openActivityCenter() {
    if (this.openNativePage('activitycenter')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openactivitycenter();
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openactivitycenter'
        });
      } catch (e) { }
    } else {
      this.tryOpen('openactivitycenter');
    }
  },
  /**
   * 调起短视频
   * @param vid
   */
  openShortVideoPlayer(id) {
    if (this.openNativePage('shortvideoplayer', null, { id: `${id}` })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openShortVideoPlayer(id);
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openShortVideoPlayer',
          id: `${id}`
        });
      } catch (e) { }
    } else {
      this.tryOpen(`openShortVideoPlayer?id=${id}`);
    }
  },
  /**
   * 调起游戏列表页
   * @param
   */
  openGameVideoList() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openGameVideoList();
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openGameVideoList'
        });
      } catch (e) { }
    } else {
      this.tryOpen('openGameVideoList');
    }
  },
  /**
   * 调起FAM列表页
   * @param filtertype 选中位置 1:家族
   */
  openMyFamList(filtertype) {
    const payload = {};
    if (filtertype) {
      payload.filtertype = `${filtertype}`;
    }
    if (this.openNativePage('myfamlist', null, payload)) {
      return;
    }

    if (filtertype) {
      if (IsKEWLApp && !IsIOS) {
        KEWLObj.openmyfamlist(filtertype);
      } else if (IsNewIosApp) {
        try {
          this.iosPostMessage({
            type: 'openmyfamlist',
            filtertype: `${filtertype}`
          });
        } catch (e) { }
      } else {
        this.tryOpen(`openmyfamlist?filtertype=${filtertype}`);
      }
    } else if (IsKEWLApp && !IsIOS) {
      KEWLObj.openmyfamlist();
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openmyfamlist'
        });
      } catch (e) { }
    } else {
      this.tryOpen('openmyfamlist');
    }
  },
  /**
   * 调起直播界面
   * @param  {number} id 直播id
   */
  openLivePage(id) {
    id = id || '';

    if (this.openNativePage('livepage', 'vid', { id: `${id}` })) {
      return;
    }
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLivePage(`${id}`);
    } else if (IsNewIosApp) {
      try {
        this.iosPostMessage({
          type: 'openlivepage',
          id: `${id}`
        });
      } catch (e) { }
    } else {
      this.tryOpen(`openlivepage?id=${id}`);
    }
  },
  /**
   * 调起回放界面
   * @param  {number} id 直播id
   */
  openReplayPage(id) {
    id = id || '';

    if (this.openNativePage('replaypage', null, { id: `${id}` })) {
      return false;
    }

    if (IsKEWLApp && !IsIOS) {
      if (this.getVerCode() < 30080500) {
        this.openLivePage(id);
      } else {
        KEWLObj.openReplayPage(`${id}`);
      }
    } else if (IsNewIosApp) {
      try {
        // iOS可自动解析
        this.iosPostMessage({
          type: 'openlivepage',
          id: `${id}`
        });
      } catch (e) { }
    } else {
      this.tryOpen(`openreplaypage?id=${id}`);
    }
  },
  /**
   * 调起播主界面
   * @param  {number} id 播主id
   */
  openBoZhuPage(id) {
    if (!id) {
      return;
    }

    if (this.openNativePage('bozhupage', null, { id: `${id || ''}` })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openBoZhuPage(`${id}`);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openbozhupage',
        id: `${id}`
      });
    } else {
      this.tryOpen(`openbozhupage?id=${id}`);
    }
  },
  /**
   * 打开首页
   */
  openHomePage(tabId) {
    if (this.openNativePage('homepage', null, { tabId: tabId || '' })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openHomePage(tabId || '');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openhomepage',
        tabId
      });
    } else {
      this.tryOpen(`openhomepage?tabId=${tabId}`);
    }
  },
  /**
   * 打开个人页面
   */
  openPersonalPage() {
    if (this.openNativePage('personalpage')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openPersonalPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openpersonalpage'
      });
    } else {
      this.tryOpen('openpersonalpage');
    }
  },
  /**
   * 打开任务列表页
   */
  openTaskListPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openTaskListPage();
    } else {
      this.tryOpen('opentasklistpage');
    }
  },
  /**
   * 打开提现页
   */
  openCashPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openCashPage('');
    } else {
      this.tryOpen('opencashpage');
    }
  },
  /**
   * 打开nft
   */
  openNft(uid, nickName) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openNft(uid, nickName);
    } else {
      this.tryOpen(`openNft?uid=${Base64.encode(uid)}&name=${Base64.encode(nickName)}`);
    }
  },
  /**
   * 打开直播推流页
   */
  openPrepareLivePage() {
    // TODO: - 安卓好像有参数，不知道需不需要传
    if (this.openNativePage('preparelivepage')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      // eslint-disable-next-line prefer-rest-params
      if (arguments.length === 0 || (arguments.length === 1 && arguments[0] === '')) {
        KEWLObj.openPrepareLivePage('');
      } else {
        // eslint-disable-next-line prefer-rest-params
        const arr = Array.prototype.slice.call(arguments);
        if (arr.length < 3) {
          for (let i = 0, len = 3 - arr.length; i < len; i += 1) {
            arr.push('');
          }
        } else {
          arr.length = 3;
        }
        // eslint-disable-next-line prefer-spread
        KEWLObj.openPrepareLivePage.apply(KEWLObj, arr)();
      }
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openpreparelivepage'
      });
    } else {
      this.tryOpen('openpreparelivepage');
    }
  },
  /**
   * 打开推荐播主列表页
   */
  openIntroduceBroadcasterPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openIntroduceBroadcasterPage('');
    } else {
      this.tryOpen('openintroducebroadcasterpage');
    }
  },
  /**
   * 打开webview
   * @param  {String} base64URL Base64 编码后的URL
   */
  openwebview(URL) {
    if (IsKEWLApp || !Base64) {
      return;
    }
    // TODO: - 这个方法还需要处理吗，都是通过scheme调用的
    this.tryOpen(`openwebview?url=${Base64.encode(URL)}`);
  },
  /**
   * 安卓下尝试打开App
   * @param  {Object} obj obj.id:如果有，尝试打开直播，没有只调起app；
   *                      obj.other:执行其他方法other{fn:'openBoZhuPage',param:[123456]}
   *                      obj.success:如果客户端调起成功，调用成功回调函数
   *                      obj.fail:如果客户端调起失败，调用失败回调函数
   */
  androidTryOpenAppOrDownload(obj) {
    const self = this;
    setTimeout(() => {
      let startTime = new Date().valueOf();
      if (!!obj && !!obj.id) {
        self.openLivePage(obj.id);
      } else if (obj.other && !!self[obj.other.fn]) {
        // eslint-disable-next-line prefer-spread
        self[obj.other.fn].apply(self, obj.other.param || []);
      } else {
        self.openApp();
      }
      startTime = new Date().valueOf();
      setTimeout(() => {
        const endTime = new Date().valueOf();
        if (endTime - startTime < 1550) {
          obj.fail && obj.fail();
        } else {
          obj.success && obj.success();
        }
      }, 1500);
    }, 100);
  },
  /**
   *tag详情页
   */
  openSearchTagPage(keyword) {
    if (
      IsKEWLApp
      && !IsIOS
      && !!keyword
      && !!KEWLObj
      && !!KEWLObj.openSearchTagPage
    ) {
      KEWLObj.openSearchTagPage('', keyword);
    }
  },
  /**
   * 关闭webview
   * @return {[type]} [description]
   */
  closePage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.closePage('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'close'
      });
    } else if (IsKEWLApp && IsIOS) {
      this.tryOpen('close');
    }
  },
  /**
   * 获取客户端版本
   * @return {Number} 如：10230000
   */
  getVerCode() {
    return getVerCode();
  },
  /**
   * 获取客户端版本
   * @return {String} 如：1.2.3
   */
  getVerName() {
    return getVerName();
  },
  /**
   * 获取android 多包信息 返回信息com.****.live
   */
  getPackName() {
    if (!!KEWLObj && !!KEWLObj.getPackName) {
      return KEWLObj.getPackName();
    }
    return '';
  },
  /**
   * 设置title
   * @param {String} title 标题文字
   */
  setTitle(title) {
    if (!title || typeof title !== 'string') {
      return;
    }
    if (!!Base64 && IsKEWLApp && !IsIOS && this.getVerCode() >= 20900000) {
      KEWLObj.changeTitle(Base64.encode(title));
    } else if (
      !!Base64
      && IsKEWLApp
      && IsIOS
      && this.getVerCode() >= 103100000
    ) {
      this.tryOpen(`changeTitle?title=${Base64.encode(title)}`);
    } else if (!!Base64 && IsNewIosApp) {
      this.iosPostMessage({
        type: 'changetitle',
        title: Base64.encode(title)
      });
    } else {
      document.title = title;
    }
  },
  /**
   * @desc 获取主播详情
   * @param {*} callback
   * @return { anchor_uid:'id',name:'主播name',anchor_face:'faceUrl'}
   */
  getAnchorInfo(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getAnchorInfo')
    ) {
      obj = KEWLObj.getAnchorInfo();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getAnchorInfo',
          callback: fnName
        });
      } else {
        this.tryOpen(`getAnchorInfo?callback=${fnName}`);
      }
    }
  },
  /**
   * @desc 获取游戏直播间详情
   * @param {*} callback
   * @return { }
   */
  getVideoInfo(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getVideoInfo')
    ) {
      obj = KEWLObj.getVideoInfo();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getVideoInfo',
          callback: fnName
        });
      } else {
        this.tryOpen(`getVideoInfo?callback=${fnName}`);
      }
    }
  },
  /**
   * 获取用户登录信息
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  getUserInfo(callback) {
    // {
    //  "deviceId":"asdfasdf",
    //  "userId":"12341234",
    //  "token":"asdfasdf"
    // }
    const userInfoSingleton = UserInfoSingleton.getInstance();
    userInfoSingleton.getUserInfo(callback);
  },
  /**
   * 复制一段文本
   * @param  {String} text 要复制的文本
   * @return {Undefined}      无返回值
   */
  copy(text) {
    if (!text) {
      return false;
    }
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getUserInfo')
    ) {
      KEWLObj.copy(`${text}`);
      return true;
    } if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'copy',
        text: `${text}`
      });
      return true;
    }
    return false;
  },
  /**
   * 是否有客户端分享界面，自执行，返回变量；
   */
  hasShare: (function () {
    return (
      (IsKEWLApp
        && !IsIOS
        && typeof KEWLObj === 'object'
        && (Object.prototype.hasOwnProperty.call(KEWLObj, 'openShareMenu')
          || Object.prototype.hasOwnProperty.call(KEWLObj, 'showShareMenu')))
      || (IsKEWLApp && IsIOS && getVerCode() >= 10800000)
    );
  }()),
  /**
   * 打开客户端分享面板
   * @param  {Object}   obj      传递配置
   * @param  {Function} callback 分享后回调
   * @return {Undefined}
   */
  openShareMenu(obj, callback) {
    const data = {
      shareTypes: '0',
      url: window.location2.href,
      content: document.querySelector('meta[name="description"]').content,
      image: `${ROOT.location2.protocol}//${ROOT.location2.hostname}/images/logo.jpg`,
      subject: document.title,
      isShowShare: true,
      private_chat_url: '',
      show_friend: 0
    };
    if (typeof obj !== 'object') {
      return;
    }

    Object.keys(data).forEach((k) => {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        data[k] = obj[k];
      }
    });

    data.callback = asyncCallbackName(callback);
    if (this.hasShare && !IsIOS) {
      KEWLObj.openShareMenu(JSON.stringify(data));
    } else if (this.hasShare && IsIOS) {
      data.type = 'openShareMenu';
      this.iosPostMessage(data);
    }
  },
  // 获取直播间入口信息
  getH5ActEntranceInfo(callback) {
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      const obj = KEWLObj.getH5ActEntranceInfo();
      callback && callback(obj);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'getH5ActEntranceInfo',
        callback: fnName
      });
    } else {
      this.tryOpen(`getH5ActEntranceInfo?callback=${fnName}`);
    }
  },
  // 改变直播间入口大小
  // status int型 0：小窗口 1：大窗口
  changeH5ActEntranceSize(status) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.changeH5ActEntranceSize(status);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'changeH5ActEntranceSize',
        status
      });
    } else {
      this.tryOpen(`changeH5ActEntranceSize?status=${status}`);
    }
  },
  /**
   * 打开客户端分享面板  新增
   * @param  {Object}   obj      传递配置
   * @param  {Function} callback 分享后回调
   * @return {Undefined}
   */
  showShareMenu(obj, callback) {
    const data = {
      shareTypes: '0',
      url: window.location2.href,
      content: document.querySelector('meta[name="description"]').content,
      image: `${ROOT.location2.protocol}//${ROOT.location2.hostname}/images/logo.jpg`,
      subject: document.title,
      isShowShare: true,
      private_chat_url: '',
      show_friend: 0
    };
    if (typeof obj !== 'object') {
      return;
    }

    Object.keys(data).forEach((k) => {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        data[k] = obj[k];
      }
    });

    data.callback = asyncCallbackName(callback);
    if (this.hasShare && !IsIOS) {
      KEWLObj.showShareMenu(JSON.stringify(data));
    } else if (this.hasShare && IsIOS) {
      data.type = 'showShareMenu';
      this.iosPostMessage(data);
    }
  },
  /**
   * iOS在浏览器中打开[safari]
   * @param  {String} url 要打开的URL
   * @return {Undefined}
   */
  openInBrowser(url) {
    if (this.openNativePage('url', 'external', { url: Base64.encode(`${url}`) })) {
      return;
    }

    if (IsKEWLApp && IsIOS && getVerCode() >= 20000000) {
      this.iosPostMessage({
        type: 'openurl',
        url: Base64.encode(`${url}`)
      });
    } else if (IsKEWLApp && KEWLObj.openurl) {
      KEWLObj.openurl(Base64.encode(`${url}`));
    } else {
      window.location.href = `${url}`;
    }
  },
  /**
   * 调起客户端充值界面，支持scheme
   * params string   json string '{srcName:"LingXianGame"}'
   * srcName=thirdPartyRecharge时，强制客户端走原生支付
   * @return {Undefined}
   */
  openRechargePage(strObj) {
    let toStr = '';
    if (typeof strObj === 'object') {
      toStr = JSON.stringify(strObj);
    } else {
      toStr = strObj;
    }

    // TODO: - 安卓也需要通过str获取参数
    if (this.openNativePage('rechargepage', null, { str: toStr })) {
      return false;
    }

    if (IsAndroidApp && KEWLObj.openRechargePage) {
      KEWLObj.openRechargePage(toStr);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openrechargepage',
        str: toStr
      });
    } else {
      this.tryOpen(`openRechargePage?str=${toStr}`);
    }
  },
  /**
   * Open crane game page.
   */
  openCatchDoll() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openCatchDoll('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openCatchDoll'
      });
    } else {
      this.tryOpen('openCatchDoll');
    }
  },
  /**
   * 打开娃娃机直播间---add by moon
   */
  openCatchLive(vid, videosource) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openCatchLive(vid, videosource);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openCatchLive',
        vid: `${vid}`,
        videosource: `${videosource}`
      });
    } else {
      this.tryOpen(`openCatchLive?vid=${vid}&videosource=${videosource}`);
    }
  },
  /**
   * 打开话题聚合页---add by moon
   */
  openTopicList() {
    if (this.openNativePage('topiclist')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openTopicList('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openTopicList'
      });
    } else {
      this.tryOpen('openTopicList');
    }
  },
  /**
   * 打开话题详情页---add by moon
   */
  openTopicDetail(topicId, topicName) {
    if (this.openNativePage('topicdetail', null, {
      vid: `${topicId}`,
      videosource: `${topicName}`,
      topicId: `${topicId}`,
      topicName: `${topicName}`
    })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openTopicDetail(topicId, topicName);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openTopicDetail',
        vid: `${topicId}`,
        videosource: `${topicName}`
      });
    } else {
      this.tryOpen(
        `openTopicDetail?topicId=${topicId}&topicName=${topicName}`
      );
    }
  },
  /**
   * @desc 跳转至动态广场话题详情页 4.4.90 添加
   * @param  tag_id: 话题ID string
   * @param  feed_count: 该话题下feed数 number
   * @param  view_count: 该话题下观看数 number
   * @param  desc: 话题描述 string
   */
  opendynamictopicdetail(tag_id, feed_count, view_count, desc) {
    if (this.openNativePage('dynamictopicdetail', null, {
      tag_id, feed_count, view_count, desc
    })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.opendynamictopicdetail(tag_id, feed_count, view_count, desc);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'opendynamictopicdetail',
        tag_id,
        feed_count,
        view_count,
        desc
      });
    } else {
      this.tryOpen(`opendynamictopicdetail?tag_id=${tag_id}&feed_count=${feed_count}&view_count=${view_count}&desc=${desc}`);
    }
  },
  /**
   * 打开短视频聚合页---add by moon
   */
  openShortVideo() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openShortVideo('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openShortVideo'
      });
    } else {
      this.tryOpen('openShortVideo');
    }
  },
  /**
   * 打开游乐场聚合页---add by moon
   */
  openPlayground() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openPlayground('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openPlayground'
      });
    } else {
      this.tryOpen('openPlayground');
    }
  },
  /**
   * @desc 下面两个是未登录获取用户的uid和设备id  用于绑定手机好  by xuerong
   * @param {*} callback
   */
  getExtraString(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getExtraString')
    ) {
      obj = KEWLObj.getExtraString();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS && this.getVerCode() >= 10400000) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getExtraString',
          callback: fnName
        });
      } else {
        this.tryOpen(`getExtraString?callback=${fnName}`);
      }
    }
  },
  performPhoneLogin(code, phoneNum, password) {
    const userString = {
      code,
      phoneNum,
      password,
      type: 'performphonelogin'
    };
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.performPhoneLogin(code, phoneNum, password);
    } else if (IsNewIosApp) {
      this.iosPostMessage(userString);
    }
  },
  // 进入聊天室 by xuerong
  openLetterPage(gid) {
    const userString = `{gid=${gid}}`;

    if (this.openNativePage('letterpage', null, { gid })) {
      return;
    }

    if (IsAndroidApp && KEWLObj.openLetterPage) {
      KEWLObj.openLetterPage(userString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLetterPage',
        gid
      });
    } else {
      this.tryOpen('openLetterPage');
    }
  },
  // 打开箱子唤起客户端弹窗 by xuerong
  robRedPacket(redPacketId) {
    const userString = `{packetid=${redPacketId}}`;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.robRedPacket(userString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'robRedPacket',
        packetid: redPacketId
      });
      return true;
    } else {
      this.tryOpen('robRedPacket');
    }
    return false;
  },
  // 进入家族群 by xuerong
  openFamilyManagerPage(gid) {
    if (this.openNativePage('familymanagerpage', null, { gid, source: 6 })) {
      return;
    }

    if (IsAndroidApp && KEWLObj.openFamilyManagerPage) {
      KEWLObj.openFamilyManagerPage(gid.toString(), 6);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openfamilymanagerpage',
        gid,
        source: 6
      });
    } else {
      this.tryOpen('openfamilymanagerpage');
    }
  },
  /*
   * @desc 获取可用域名
   * @params domain   域名字符串  逗号分隔
   * @params callback   回调函数
   * */
  getApiDomain(domain, callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getApiDomain')
    ) {
      obj = KEWLObj.getApiDomain(domain);
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS && this.getVerCode() >= 10400000) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getApiDomain',
          domain,
          callback: fnName
        });
      } else {
        this.tryOpen(`getApiDomain?domain=${domain}&callback=${fnName}`);
      }
    }
  },
  // 2019.03.25 liuyanan add
  /**
   * 获取用户登录信息
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  getBindInfo(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getBindInfo')
    ) {
      obj = KEWLObj.getBindInfo();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS && this.getVerCode() >= 10400000) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getBindInfo',
          callback: fnName
        });
      } else {
        this.tryOpen(`getBindInfo?callback=${fnName}`);
      }
    }
  },
  /**
   * @desc 登出方法
   * @method  logoutApp
   */
  logoutApp() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.logoutApp();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'logoutApp'
      });
    } else {
      this.tryOpen('logoutApp');
    }
  },
  /**
   * by xuerong
   * app内，从一个容器点击链接跳转到另外一个容器
   * @param  {String} url 要打开的URL  neadHead 打开的浏览器是否需要头
   * @param  transition : 1 ios特有调用此方法标识页面 1=>标识新协议更新弹窗页
   */
  openUrlInternal(url, neadHead, transition) {
    const nd = neadHead || true;
    const enUrl = Base64.encode(url);

    if (this.openNativePage('url', 'internal', { url: enUrl, neadhead: nd, transition })) {
      return;
    }

    if (IsAndroidApp && KEWLObj.openUrlInternal) {
      KEWLObj.openUrlInternal(enUrl, nd);
    } else if (IsNewIosApp) {
      const argObj = {
        type: 'openUrlInternal',
        url: enUrl,
        neadhead: nd
      };
      if (transition) {
        argObj.transition = transition;
      }
      this.iosPostMessage(argObj);
    }
  },


  /**
   * by lizheng
   * app内，从一个容器点击链接跳转到另外一个容器
   * @param  {String} url 要打开的URL  neadHead 打开的浏览器是否需要头
   */
  openUrlFullScreen(url, neadHead) {
    const nd = !!neadHead;
    const enUrl = Base64.encode(url);

    if (this.openNativePage('url', 'fullscreen', { url: enUrl, neadhead: nd })) {
      return;
    }

    if (IsAndroidApp && KEWLObj.openUrlFullScreen) {
      KEWLObj.openUrlFullScreen(enUrl, nd);
    } else if (IsNewIosApp) {
      const argObj = {
        type: 'openUrlFullScreen',
        url: enUrl,
        neadhead: nd
      };
      this.iosPostMessage(argObj);
    }
  },
  /*
   * 直播间打开礼物面板 => haoshaohua
   * tabid => 礼物面板tabid
   * activityid => 活动id
   * tabid、activityid要传值需要都传，否则都不传
   * source 4.3.30版本添加字段 标识来源
   */
  openSendGifts(tabidPram, activityidPram, sourcePram) {
    const tabid = tabidPram || '';
    const activityid = activityidPram || '';
    const source = sourcePram || '';
    if (this.versionCompare('4.3.30')) {
      if (IsKEWLApp && !IsIOS) {
        KEWLObj.openSendGifts(tabid, activityid, source);
      } else if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'openSendGifts',
          tabid: `${tabid}`,
          activityid: `${activityid}`,
          source: `${source}`
        });
      } else {
        this.tryOpen(`openSendGifts?tabid=${tabid}&activityid=${activityid}&source=${source}`);
      }
    } else if (IsKEWLApp && !IsIOS) {
      KEWLObj.openSendGifts(tabid, activityid);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openSendGifts',
        tabid: `${tabid}`,
        activityid: `${activityid}`
      });
      this.tryOpen(`openSendGifts?tabid=${tabid}&activityid=${activityid}`);
    }
  },
  /*
   * 直播间更新礼物面板星光数 => haoshaohua
   * star_num => 星光数
   * star_sign => 星光数加密数据，md5加密，加密算法参考app/starlight项目
   * star_num、star_sign两个参数都需要传
   */
  updateStar(starNumPrama, starSignPrama) {
    const starNum = starNumPrama || '';
    const starSign = starSignPrama || '';
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.updatestar(starNum, starSign);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'updatestar',
        star_num: `${starNum}`,
        star_sign: `${starSign}`
      });
    } else {
      this.tryOpen(
        `updatestar?star_num=${starNum}&star_sign=${starSign}`
      );
    }
  },
  /*
   * 首充方法 => haoshaohua
   */
  openFirstRechargeCommodity(source) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openFirstRechargeCommodity(source || '');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openFirstRechargeCommodity',
        source: source || ''
      });
    } else {
      this.tryOpen(
        `openFirstRechargeCommodity?source=${source}`
      );
    }
  },
  /*
   * 首充获取数据 => haoshaohua
   */
  getFirstRechargeInfos(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
    ) {
      obj = KEWLObj.getFirstRechargeInfos();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getFirstRechargeInfos',
          callback: fnName
        });
      } else {
        this.tryOpen(`getFirstRechargeInfos?callback=${fnName}`);
      }
    }
  },
  /*
   * 打开充值面板 => haoshaohua
   */
  openChargePanel(srcName) {
    if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openchargepanel',
        srcName
      });
    } else {
      let jsonString = '';
      if (typeof srcName === 'string') {
        jsonString = JSON.stringify({ srcName });
      } else {
        jsonString = JSON.stringify(srcName);
      }
      if (IsKEWLApp && !IsIOS && typeof KEWLObj === 'object' && Object.prototype.hasOwnProperty.call(KEWLObj, 'openChargePanel')) {
        KEWLObj.openChargePanel(jsonString);
      } else if (IsIOS) {
        this.tryOpen(`openChargePanel?srcName=${srcName}`);
      } else {
        this.tryOpen(`openChargePanel?srcName=${jsonString}`);
      }
    }
  },
  /*
   * 获取充值打折信息 => haoshaohua
   * callback => 回调函数，返回值 => { localCurrency: '$0.99' }
   * localCurrency => 客户端返回打折档位本地货币信息
   */
  getDiscountRechargeCurrency(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
    ) {
      obj = KEWLObj.getDiscountRechargeCurrency();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getDiscountRechargeCurrency',
          callback: fnName
        });
      } else {
        this.tryOpen(`getDiscountRechargeCurrency?callback=${fnName}`);
      }
    }
  },
  /*
   * 进行打折充值 => haoshaohua
   * srcName => 页面唯一标识
   */
  openDiscountRecharge(srcName) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openDiscountRecharge(srcName || '');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openDiscountRecharge',
        srcName: srcName || ''
      });
    } else {
      this.tryOpen(
        `openDiscountRecharge?srcName=${srcName}`
      );
    }
  },
  /*
   * 直播间主播端打开键盘 => haoshaohua
   * text => 键盘内预输入的内容
   */
  openDiscountRechargeKeyboard(text) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openDiscountRechargeKeyboard(text || '');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openDiscountRechargeKeyboard',
        text: text || ''
      });
    } else {
      this.tryOpen(
        `openDiscountRechargeKeyboard?text=${text}`
      );
    }
  },
  /*
   * 安卓GP替换三方充值，通知客户端刷新金币
   */
  refreshGold(result) {
    if (IsKEWLApp && IsAndroid) {
      KEWLObj.refreshGold && KEWLObj.refreshGold(result);
    }
  },
  // 2019.04.25 xuerong add
  /**
   * 获取皮肤信息
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  getThemeInfo(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getThemeInfo')
    ) {
      obj = KEWLObj.getThemeInfo();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getThemeInfo',
          callback: fnName
        });
      } else {
        this.tryOpen(`getThemeInfo?callback=${fnName}`);
      }
    }
  },
  /*
   * @desc 接口请求 by xuerong
   * @params info  接口参数json字符串
   * @params callback   回调函数
   * */
  getRequest(info, callback) {
    let obj;
    const fnName = asyncCallbackName(callback);
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getRequest')
    ) {
      obj = KEWLObj.getRequest(info, fnName);
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS && this.getVerCode() >= 10400000) {
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getRequest',
          info,
          callback: fnName
        });
      } else {
        this.tryOpen(`getRequest?info=${info}&callback=${fnName}`);
      }
    }
  },
  /*
   * 页面加载完成回掉给客户端，用于埋点统计 => by xuerong
   * url：string => 当前页面的url地址
   * result：boolean => 页面是否加载完成
   * perf: string => 取浏览器的
   */
  pageFinish(urlPrama, perfPrama) {
    const url = urlPrama || '';
    const perf = perfPrama || '';
    if (IsKEWLApp && !IsIOS && typeof KEWLObj.pageFinish === 'function') {
      KEWLObj.pageFinish(url, perf);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'pageFinish',
        url,
        performance: perf
      });
    } else {
      this.tryOpen(`pageFinish?url=${url}&performance=${perf}`);
    }
  },
  /*
   * 页面加载结束，用于埋点统计 => by xuerong
   * url：string => 当前页面的url地址
   */
  pageShow(urlPrama) {
    const url = urlPrama || '';
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.pageShow(url);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'pageShow',
        url
      });
    } else {
      this.tryOpen(`pageShow?url=${url}`);
    }
  },
  /**
   * @desc 获取app 包 信息
   * @return {String} 包信息  liveme 包   中东包
   */
  getPackgetInfo() {
    return packageInfo;
  },
  /**
   * @desc 获取alias
   * @returns {String} alias
   */
  getAlias() {
    // 兼容coinsgame独立域名支持多包
    // 根据getPackName获取包名
    const packName = window.KEWLApp.getPackName && window.KEWLApp.getPackName();
    if (packName.split('.')[1]) {
    // eslint-disable-next-line prefer-destructuring
      const packForCoinsgame = packName.split('.')[1];
      if (packForCoinsgame === 'sparkher') {
        return 'highlive';
      }
      if (packForCoinsgame === 'highlive') {
        return 'highlive';
      }
      if (packForCoinsgame === 'chasex') {
        return 'highlive';
      }
    }
    return alias;
  },
  /**
   * @desc 获取风控sessionid 信息  执行画像操作
   * @params {Function} callback(res)
   * @return {String} sessionid
   */
  doTmxProfile(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'doTmxProfile')
    ) {
      obj = KEWLObj.doTmxProfile();
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'doTmxProfile',
          callback: fnName
        });
      } else {
        this.tryOpen(`doTmxProfile?callback=${fnName}`);
      }
    }
  },
  /**
   * @desc 调起vip 客服页面 4.0.90版本加入
   * @param {String} orderId
   * @param {String} channel
   * @param {String} errorCode
   * @param {String} errorMsg
   */
  openVIPService(orderId, channel, errorCode, errorMsg) {
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'openVIPService')
    ) {
      KEWLObj.openVIPService(orderId, channel, errorCode, errorMsg);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openVIPService',
        orderId: `${orderId}`,
        channel: `${channel}`,
        errorCode: `${errorCode}`,
        errorMsg: `${errorMsg}`
      });
    } else {
      this.tryOpen(
        `openVIPService?tabid=${orderId
        }&channel=${channel
        }&errorCode=${errorCode
        }&errorMsg=${errorMsg}`
      );
    }
  },
  /* 比较版本号大小，非客户端方法
   * @desc 通过getVerName取当前版本进行匹配
   * @use versionCompare(currentVer, '4.0.95')
  */
  versionCompare(ver) {
    const currentVer = getVerName();
    // 版本号有返回为空的情况
    if (currentVer === null) {
      return false;
    }
    const version1pre = parseFloat(currentVer);
    const version2pre = parseFloat(ver);
    const version1next = currentVer.replace(`${version1pre}.`, '');
    const version2next = ver.replace(`${version2pre}.`, '');
    if (version1pre > version2pre) {
      return true;
    } if (version1pre < version2pre) {
      return false;
    } if (version1next >= version2next) {
      return true;
    }
    return false;
  },
  /**
   * @desc 给客户端传配置信息（家族活动中心）大于4.0.95 => by xuerong
   * @param {String} jsonStr
   */
  onFamilyActStart(jsonStr) {
    const jsonString = jsonStr || null;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.onFamilyActStart(jsonString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'onFamilyActStart',
        jsonString
      });
    } else {
      this.tryOpen(`onFamilyActStart?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc 给客户端传配置信息（家族活动中心）大于4.0.95 => by xuerong
   * @param {String} jsonStr
   */
  onFamilyActEnd(jsonStr) {
    const jsonString = jsonStr || null;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.onFamilyActEnd(jsonString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'onFamilyActEnd',
        jsonString
      });
    } else {
      this.tryOpen(`onFamilyActEnd?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc 打开粉丝标签 => by xuerong
   * @param {String} jsonString
   */
  openFansTagPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openFansTagPage('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openFansTagPage'
      });
    } else {
      this.tryOpen('openFansTagPage');
    }
  },
  /**
   * @desc 主播任务系统 => by xuerong
           hostmission
           actionType: 1 //任务类型 默认0
           actionName: "" // 任务名
           picUrl: "" // 引导图片地址
           callback
   * @param {String} jsonStr
   */
  hostMission(jsonStr, callback) {
    const jsonString = jsonStr || null;
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj.hostmission(jsonString);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: 'hostmission',
        jsonString,
        callback: fnName
      });
    } else {
      this.tryOpen(`hostmission?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc 金币结算后，通知客户端 => by xuerong
   * @param {Number} _balance
   */
  onSettleAccount(_balance) {
    const balance = _balance || null;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.onSettleAccount(balance);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'onSettleAccount',
        balance
      });
    } else {
      this.tryOpen(`onSettleAccount?balance=${balance}`);
    }
  },
  /**
   * @desc 打开私聊面板 => by xuerong [ 两端实现不一致，所有参数必传 ]
   * @param {String} uid | {String} face  | {String} nickName  | {Number} is_relation  | {Number} level | {String} sex
   */
  openPrivateChatroom(jsonStr) {
    const jsonString = jsonStr || null;

    if (this.openNativePage('privatechatroom', null, { chatinfo: jsonString })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openprivatechatroom(jsonString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openprivatechatroom',
        chatinfo: jsonString
      });
    } else {
      this.tryOpen(`openprivatechatroom?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc open api => by xuerong
   */
  onApiAuthorization() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.onApiAuthorization();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'onApiAuthorization'
      });
    } else {
      this.tryOpen('onApiAuthorization');
    }
  },
  /**
   * 图片压缩处理
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  autoZoomAndClipImage(args, callback) {
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      args.callback = fnName;
      KEWLObj.autoZoomAndClipImage(JSON.stringify(args));
    } else if (IsKEWLApp && IsIOS && this.getVerCode() >= 10400000) {
      if (IsNewIosApp) {
        args.type = 'autozoomandclipimage';
        args.callback = fnName;
        this.iosPostMessage(args);
      } else {
        this.tryOpen(`autoZoomAndClipImage?ver=${args.ver}&images=${JSON.stringify(args.images)}&callback=${fnName}`);
      }
    }
  },
  /**
   * @desc 打开背包
   * @param {Number} bagTabId
   */
  openBagPage(bagTabId = 0) {
    if (this.openNativePage('bagpage', null, { effectType: `${bagTabId}` })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openBagPage(bagTabId);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openBagPage',
        effectType: `${bagTabId}`
      });
    } else {
      this.tryOpen(`openBagPage?effectType=${bagTabId}`);
    }
  },
  /**
   * @desc ios获取app信息
   */
  getAppInfo(callback) {
    let obj;
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj.getappinfo();
      callback && callback(obj);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'getappinfo',
        callback: fnName
      });
    } else {
      this.tryOpen(`getappinfo?callback=${fnName}`);
    }
  },
  /**
   * @desc 给客户端传配置信息（家族活动中心）大于4.0.95 => by xuerong
   * @param {String} _jsonBean
   * @param {init} type
   */
  refreshNative(refreshType, _jsonBean) {
    const jsonBean = _jsonBean || '';
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.refreshNative(refreshType, jsonBean);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'refreshNative',
        refreshType,
        jsonBean
      });
    } else {
      this.tryOpen(`refreshNative?refreshType=${refreshType}&jsonBean=${jsonBean}`);
    }
  },
  /**
   * @desc 通知客户端信息改变
   * @param {String} _jsonBean
   * @param {string} placeType
   */
  postDataToNative(_jsonBean) {
    const jsonBean = _jsonBean || '';
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.postDataToNative(jsonBean);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'postDataToNative',
        jsonBean
      });
    } else {
      this.tryOpen(`postDataToNative?jsonBean=${jsonBean}`);
    }
  },
  /**
   * @desc 通知客户端信息改变(vue3版本)
   * @param {String} _jsonBean
   * @param {string} placeType
   */
  postDataToNativeV3(placeType, _jsonBean) {
    // const jsonBean = _jsonBean || '';
    const data = Object.assign({}, _jsonBean, { placeType });
    const jsonBean = JSON.stringify(data);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.postDataToNative(jsonBean);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'postDataToNative',
        jsonBean
      });
    } else {
      this.tryOpen(`postDataToNative?jsonBean=${jsonBean}`);
    }
  },
  /**
   * @desc 唤起购买守护
   */
  purchaseGuard() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.purchaseGuard();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'purchaseGuard'
      });
    } else {
      this.tryOpen('purchaseGuard');
    }
  },
  /**
   * @desc 打开家族成员列表 => by xuerong
           gid: 群ID
           role_id: 角色ID
   * @param {String} jsonStr
   */
  openFamilyList(jsonStr, callback) {
    const jsonString = jsonStr || null;
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj.openFamilyList(jsonString);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: 'openFamilyList',
        jsonString,
        callback: fnName
      });
    } else {
      this.tryOpen(`openFamilyList?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc 返回刷新h5页面（目前用在kingdom-task） => by xuerong
   * @param {String} fun  传window方法，参考kingdom-task
   */
  updateH5Page(fun) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.updateH5Page(fun);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'updateH5Page',
        fun
      });
    } else {
      this.tryOpen('updateH5Page');
    }
  },
  /**
   * @desc 跳转到客服页面（普通实时客服） => by xuerong
   * @param {String} params
   */
  openCustomerService(jsonString, callback) {
    const params = jsonString || '';
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj.openCustomerService(params);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      console.log(fnName);
      this.iosPostMessage({
        type: 'openCustomerService',
        params
      });
    } else {
      this.tryOpen(`openCustomerService?params=${params}`);
    }
  },
  /**
   * @desc 打开个人编辑页 => by xuerong
   * @param {String} jsonStr
   */
  openEditPage(jsonStr, callback) {
    const jsonString = jsonStr || '';
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj.openEditPage(jsonString);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: 'openEditPage',
        jsonString,
        callback: fnName
      });
    } else {
      this.tryOpen(`openEditPage?jsonString=${jsonString}`);
    }
  },
  /**
   * @desc 控制客户端返回箭头(仅ios需要) => by xuerong
   * @param {String} jsonStr
   */
  hideBackButton(jsonStr, callback) {
    const jsonString = jsonStr || '';
    if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: 'hideBackButton',
        jsonString,
        callback: fnName
      });
    }
  },
  /**
   * @desc 跳转至对应的动态详情页 4.3.50 添加
   * @param {String} id
   */
  opendynamicdetail(id) {
    if (this.openNativePage('dynamicdetail', null, { id: `${id}` })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.opendynamicdetail(`${id}`);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'opendynamicdetail',
        id: `${id}`
      });
    } else {
      this.tryOpen(`opendynamicdetail?id=${id}`);
    }
  },
  /**
   * @desc 跳转至动态列表页面 4.3.50 添加
   */
  opendynamiclist() {
    if (this.openNativePage('dynamiclist')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.opendynamiclist();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'opendynamiclist'
      });
    } else {
      this.tryOpen('opendynamiclist');
    }
  },
  /**
   * @desc 跳转至动态发布页面 4.3.50 添加
   */
  opendynamicrelease() {
    if (this.openNativePage('dynamicrelease')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.opendynamicrelease();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'opendynamicrelease'
      });
    } else {
      this.tryOpen('opendynamicrelease');
    }
  },
  /**
   * @desc 通用调用客户端方法
   * @param {bridge} 客户端方法，{jsonString} 通用json字符串参数、{callback} 回调
   */
  kewlBridge(bridge, jsonStr, callback) {
    const jsonString = jsonStr || '';
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj[bridge](jsonString);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: bridge,
        jsonString,
        callback: fnName
      });
    }
  },
  /**
   * @desc 通用调用客户端方法
   * @param { bridge } 客户端方法，
   * @param { json } 通用json 对象
   * @param { keys } json对象 key 顺序数组
   * @param { callback } 回调
   * [ 与原版 区别在于 参数 依次传入 而不是 格式化 给到 客户端，符合客户端习惯 ]
   */
  kewlBridgeV2(bridge, json, keys, callback) {
    const jsonData = json || {};
    const jsonKeys = keys || [];
    let obj;
    // eslint-disable-next-line no-confusing-arrow
    const values = jsonKeys.map(name => jsonData[name] !== undefined ? jsonData[name] : '');
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj[bridge](...values);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage(Object.assign({
        type: bridge,
        callback: fnName
      }, jsonData));
    }
  },
  /**
   * @desc 寻仙游戏 bridge 封装处理（寻仙游戏接入）
   * @param {bridge} 客户端方法，{jsonString} 通用json字符串参数、{callback} 回调
   */
  audioGameBridge(bridge, jsonStr, callback) {
    const jsonString = jsonStr || '';
    let obj;
    if (IsKEWLApp && !IsIOS) {
      obj = KEWLObj[bridge](jsonString);
      callback && callback(obj);
    } else if (IsNewIosApp) {
      const fnName = asyncCallbackName(callback);
      this.iosPostMessage({
        type: bridge,
        jsonString,
        callback: fnName
      });
    }
  },
  /**
   * @desc 发起拼团或加入拼团
   * @param productId
   * @param groupId
   * @method spellGroupPay
   */
  spellGroupPay(productId, groupId, gold) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.spellGroupPay(productId, groupId, gold);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'spellGroupPay',
        productId,
        groupId,
        gold
      });
    } else {
      this.tryOpen(`spellGroupPay?productId=${productId}&groupId=${groupId}&gold=${gold}`);
    }
  },
  /**
   * @desc 弹出邀请用户加入拼团全屏页面
   * @method showInviteUserPage
   */
  showInviteUserPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.showInviteUserPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'showInviteUserPage'
      });
    } else {
      this.tryOpen('showInviteUserPage');
    }
  },
  /**
   * @desc 首次送礼
   * @param
   * @method firstGiftGive
   */
  firstGiftGive() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.firstGiftGive();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'firstGiftGive'
      });
    } else {
      this.tryOpen('firstGiftGive');
    }
  },
  /**
   * @desc 持续看播30s
   * @param
   * @method continuousWatchLiveThirtySeconds
   */
  continuousWatchLiveThirtySeconds() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.continuousWatchLiveThirtySeconds();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'continuousWatchLiveThirtySeconds'
      });
    } else {
      this.tryOpen('continuousWatchLiveThirtySeconds');
    }
  },
  /**
   * @desc 调起充值弹窗
   * @param
   * @method openFirstRechargeTaskPage
   */
  openFirstRechargeTaskPage() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openFirstRechargeTaskPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openFirstRechargeTaskPage'
      });
    } else {
      this.tryOpen('openFirstRechargeTaskPage');
    }
  },
  /**
   * @desc 新手任务返回
   * @param
   * @method newbieTasksPageGoBack
   */
  newbieTasksPageGoBack() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.newbieTasksPageGoBack();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'newbieTasksPageGoBack'
      });
    } else {
      this.tryOpen('newbieTasksPageGoBack');
    }
  },
  /**
   * 获取客户端档位信息
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  getNativeProductInfo(callback) {
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getNativeProductInfo')
    ) {
      obj = KEWLObj.getNativeProductInfo('');
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const fnName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: 'getNativeProductInfo',
          callback: fnName
        });
      } else {
        this.tryOpen(`getNativeProductInfo?callback=${fnName}`);
      }
    }
  },
  /**
   * @desc 调起输入法
   * @param
   * @method openInputMethod
   */
  openInputMethod() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openInputMethod();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openInputMethod'
      });
    } else {
      this.tryOpen('openInputMethod');
    }
  },
  /*
   * 半屏打开礼物面板
   * tabType => 礼物面板tabid
   * activityid => 活动id
   * isNeedOpen 打开礼物面板，必传1
   */
  showGiftPanel(tabidPram, activityidPram, isNeedOpen) {
    const tabid = tabidPram >= 0 ? tabidPram : '';
    const activityid = activityidPram || '';
    const isOpen = isNeedOpen || 1;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.closePage('');
      KEWLObj.showGiftPanel(tabid, activityid, isOpen);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'showGiftPanel',
        tabType: `${tabid}`,
        activityid: `${activityid}`,
        isNeedOpen: `${isOpen}`
      });
    } else {
      this.tryOpen(`showGiftPanel?tabType=${tabid}&activityid=${activityid}`, `&isNeedOpen=${isOpen}`);
    }
  },
  /**
   * @desc 打开直播间
   * @param
   * @method openLiveRoom
   */
  openLiveRoom() {
    if (this.openNativePage('livepage', 'recomand')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLiveRoom();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLiveRoom'
      });
    } else {
      this.tryOpen('openLiveRoom');
    }
  },
  /**
   * @desc 打开直播间贴纸面板
   * @method openStickerMenu
   */
  openStickerMenu() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openStickerMenu();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openStickerMenu'
      });
    } else {
      this.tryOpen('openStickerMenu');
    }
  },
  /**
   * @desc 打开beam面板
   * @param role:  1 主播端  2 观众端  0 不区分
   * @param vtype:  1 普通直播  2 游戏  3 联合直播 8/9连麦 10 语音房间
   * @method openBeamMenu
   */
  openBeamMenu(role, vtype) {
    const jsonString = {
      role,
      vtype
    };
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openBeamMenu(JSON.stringify(jsonString));
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openBeamMenu',
        role,
        vtype
      });
    } else {
      this.tryOpen(`openBeamMenu?jsonString=${JSON.stringify(jsonString)}`);
    }
  },
  /*
   * 通知客户端已签到\H5关注主播后通知app修改关注状态 => liuyanan
   * signin => 参数
   * info => 签到天数/uid
   */
  taskfinish(signin, info) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.taskfinish(signin, info);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'taskfinish',
        task: signin || '',
        info: info || 0
      });
    } else {
      this.tryOpen(`taskfinish?task=${signin}&info=${info}`);
    }
  },
  /**
   * @desc 打开系统通知设置页
   * @method openSystemSetting
   */
  openSystemSetting() {
    if (this.openNativePage('systemsetting')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openSystemSetting();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openSystemSetting'
      });
    } else {
      this.tryOpen('openSystemSetting');
    }
  },
  /**
   * @desc 直播间打开半屏h5页
   * @param URL
   * @method openLiveH5Page
   */
  openLiveH5Page(URL) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLiveH5Page(URL);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLiveH5Page',
        url: URL
      });
    } else {
      this.tryOpen(`openLiveH5Page?url=${URL}`);
    }
  },
  /**
   * @desc 打开半屏分享
   * @param tabIndex recent = 0   fans = 1  social = 2
   * @param vtype:  1 普通直播  2 游戏  3 联合直播 8/9连麦 10 语音房间
   * @param content 分享内容
   * @param context 分享功能上下文
   * @method openShareLivePanel
   */
  openShareLivePanel(tabIndex, vtype, content, context) {
    const jsonString = {
      tabIndex,
      vtype,
      content,
      context
    };
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openShareLivePanel(JSON.stringify(jsonString));
    } else if (IsNewIosApp) {
      const obj = {
        type: 'openShareLivePanel',
        tabIndex,
        vtype
      };
      if (content || context) {
        obj.content = content;
        obj.context = context;
      }
      this.iosPostMessage(obj);
    } else {
      this.tryOpen(`openShareLivePanel?jsonString=${JSON.stringify(jsonString)}`);
    }
  },
  /**
   * @desc 调起充值支付（传参：档位id）
   * @param _productId:  档位id
   * @param _source:  分享 (init 类型)
   * @method startRechargeActivity
   */
  startRechargeActivity(_productId, _source) {
    const productId = typeof _productId === 'string' ? _productId : JSON.stringify(_productId);
    // eslint-disable-next-line no-restricted-properties
    const source = _source && !window.isNaN(_source) ? _source - 0 : '';
    if (IsKEWLApp && !IsIOS) {
      if (source === '') {
        KEWLObj.startRechargeActivity(productId);
      } else {
        // 来源字段存在 需要使用安卓新方法
        KEWLObj.startRechargeActivityV2(productId, source);
      }
    } else if (IsNewIosApp) {
      const msg = {
        type: 'startRechargeActivity',
        product_id: productId
      };
      if (source) {
        msg.source = source;
      }
      this.iosPostMessage(msg);
    } else {
      this.tryOpen(`startRechargeActivity?product_id=${productId}`);
    }
  },
  /**
   * @desc 关闭webview，打开军团任务页
   * @param legionID
   * @method openLegionTask
   */
  openLegionTask(legionID) {
    if (this.openNativePage('legiontask', null, { legionID })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLegionTask(legionID);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLegionTask',
        legionID
      });
    } else {
      this.tryOpen(`openLegionTask?legionID=${legionID}`);
    }
  },
  /**
   * @desc 打开军团首页
   * @param legionId
   * @method openLegionDetail
   */
  openLegionDetail(legionId) {
    if (this.openNativePage('legiondetail', null, { legionId })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLegionDetail(legionId);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLegionDetail',
        legionId
      });
    } else {
      this.tryOpen(`openLegionDetail?legionId=${legionId}`);
    }
  },
  /**
   * @desc 打开军团聊天页
   * @method openLegionRoom
   */
  openLegionRoom() {
    if (this.openNativePage('legionroom')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLegionRoom();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLegionRoom'
      });
    } else {
      this.tryOpen('openLegionRoom');
    }
  },
  /**
   * @desc 打开军团分享面板
   * @method openLegionSharePanel
   */
  openLegionSharePanel() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLegionSharePanel();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLegionSharePanel'
      });
    } else {
      this.tryOpen('openLegionSharePanel');
    }
  },
  /**
   * @desc 打开军团动态页
   * @method openLegionDynamic
   */
  openLegionDynamic() {
    if (this.openNativePage('legiondynamic')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openLegionDynamic();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openLegionDynamic'
      });
    } else {
      this.tryOpen('openLegionDynamic');
    }
  },
  /**
   * @desc 打开主播的会员中心页
   * @method openAnchorVipCenter
   */
  openAnchorVipCenter() {
    if (this.openNativePage('anchorvipcenter')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openanchorvipcenter();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openanchorvipcenter'
      });
    } else {
      this.tryOpen('openanchorvipcenter');
    }
  },
  /**
   * @desc 打开发布付费动态页
   * @method openActivityDynamicPaidPost
   */
  openActivityDynamicPaidPost() {
    if (this.openNativePage('activitydynamicpaidpost')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openactivitydynamicpaidpost();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openactivitydynamicpaidpost'
      });
    } else {
      this.tryOpen('openactivitydynamicpaidpost');
    }
  },
  /**
   * @desc 打开用户的订阅管理页（我订阅的主播）
   * @method openAudienceSubscribeManager
   */
  openAudienceSubscribeManager() {
    if (this.openNativePage('audiencesubscribemanager')) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openaudiencesubscribemanager();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openaudiencesubscribemanager'
      });
    } else {
      this.tryOpen('openaudiencesubscribemanager');
    }
  },
  /**
   * @desc 打开军团广场
   * @method openLegionPage
   * @param legionId
   */
  openLegionPage(legionId) {
    if (this.openNativePage('legionpage', null, { legionId, legionID: legionId })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openlegionpage(legionId);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openlegionpage',
        legionId
      });
    } else {
      this.tryOpen(`openlegionpage?legionId=${legionId}`);
    }
  },
  /**
   * @desc 吊起客户端私信面板,客户端区分全屏、半屏 区别于openLetterPage
   * @method openPrivateChatPage
   * @param uid
   * @param source
   * @param nickname
   * @param face
   */
  openPrivateChatPage(uid, source, nickname, face) {
    const data = {
      uid, source, nickname, face
    };
    const jsonString = JSON.stringify(data);
    if (this.openNativePage('privatechatpage', null, { jsonString })) {
      return;
    }

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openprivatechatpage(jsonString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openprivatechatpage',
        jsonString
      });
    } else {
      this.tryOpen(`openprivatechatpage?jsonString=${jsonString}`);
    }
  },

  /**
   * 自定义方法 获取  客户端返回值
   * @param  {Function} fnName 与客户端约定的 获取方法的名称
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  getNativeData(fnName, callback) {
    if (!fnName) {
      return;
    }
    const name = fnName || '';
    let obj;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, name)
    ) {
      obj = KEWLObj[name]();
      obj = typeof obj === 'string' && obj !== 'null' && obj !== ''
        ? JSON.parse(obj)
        : obj;
      callback && callback(obj);
    } else if (IsKEWLApp && IsIOS) {
      const callbackName = asyncCallbackName(callback);
      if (IsNewIosApp) {
        this.iosPostMessage({
          type: name,
          callback: callbackName
        });
      } else {
        this.tryOpen(`${name}?callback=${callbackName}`);
      }
    }
  },

  /**
   * 送礼
   * @param {Object} obj <giftId: string, sendNum: string>
   * @param {Function} callback
   * 回调参数：
   * 0送礼失败，原因不明
   * 1送礼成功
   * 2送礼失败，用户不存在礼物
   */
  sendGift(obj, callback) {
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      obj.fnName = fnName;
      KEWLObj.sendGift(JSON.stringify(obj));
      // callback(result);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'sendGift',
        obj,
        callback: fnName
      });
    } else {
      this.tryOpen(`sendGift?obj=${obj}&callback=${fnName}`);
    }
  },
  // sayhi打招呼方法
  sendSayHiMsg(params) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.sendSayHiMsg(JSON.stringify(params));
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'sendSayHiMsg',
        params
      });
    } else {
      this.tryOpen(`sendSayHiMsg?params=${params}`);
    }
  },
  /**
   * @desc 通用版 - 尝试打开App【 20210811 增加 新方法 】
   * @param { Object } obj  obj.other: 执行其他方法other{fn:'openBoZhuPage',param:[123456]}
   *                        obj.path: 打开对应页面，没有只调起app 参数直接传 如：{path: 'openroom',roomid: 123,userid: 345 }
   *                        obj.success: 如果客户端调起成功，调用成功回调函数
   *                        obj.fail: 如果客户端调起失败，调用失败回调函数
   * @method tryOpenAppByPath
   */
  tryOpenAppByPath(obj, fallbackUrl = null) {
    const params = JSON.parse(JSON.stringify(obj));
    delete params.other;
    delete params.path;

    const self = this;
    setTimeout(() => {
      // let startTime = new Date().valueOf();
      // 设置 超时时间 判断成功或失败
      // eslint-disable-next-line no-underscore-dangle
      self._setTimeEvent(
        (param) => {
          obj.success && obj.success(param);
        },
        (param) => {
          obj.fail && obj.fail(param);
        },
        (param) => {
          obj.unknown && obj.unknown(param);
        },
        2000
      );
      if (obj.other && !!self[obj.other.fn]) {
        // eslint-disable-next-line prefer-spread
        self[obj.other.fn].apply(self, obj.other.param || []);
      } else if (obj.path) {
        // 根据路径 打开 app 的页面
        const strParams = json2url(params) || '';
        const URL = `${obj.path}?${strParams}`;
        self.tryOpen(URL, fallbackUrl);
      } else {
        self.openHomePage();
      }
    }, 100);
  },

  /**
   * 拉取礼物列表
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  fetchAreaGiftList(callback) {
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.fetchAreaGiftList(fnName);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'fetchAreaGiftList',
        fnName
      });
    } else {
      this.tryOpen(`fetchAreaGiftList?fnName=${fnName}`);
    }
  },

  /**
   * 拉取礼物列表
   * @param  {Array<{gift_id: string, gift_icon: string, gift_num_received: number, gift_num_expect: number}>} data 心愿单数据
   * @return {Undefined}         无返回值，通过callback返回
   */
  updateWishList(data) {
    const json = JSON.stringify(data);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.updateWishList(json);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'updateWishList',
        json
      });
    } else {
      this.tryOpen(`updateWishList?json=${json}`);
    }
  },

  /**
   * 获取web host
   * @param {*} callback
   */
  getHost(callback) {
    const fnName = asyncCallbackName(callback);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.getHost(fnName);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'getHost',
        fnName
      });
    }
  },

  /**
   * 下载离线包
   * @param name 离线包项目名称
   * @param version 离线包版本
   */
  downloadWebZipRes(name, version) {
    const prex = `/app/${name}`;
    const zipPath = `${ROOT.location2.origin}/app/${name}/dist/${name}_${version}.zip`;
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.downloadWebZipRes && KEWLObj.downloadWebZipRes(prex, zipPath);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'downloadWebZipRes',
        prex,
        zipPath
      });
    }
  },

  /**
   * 卸载离线包 4.5.65增加
   * @param name 离线包项目名称
   * @param version 离线包版本
   */
  deleteWebZipRes(prex) {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.deleteWebZipRes && KEWLObj.deleteWebZipRes(prex);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'deleteWebZipRes',
        prex
      });
    }
  },

  /**
   * 调起客户端某个支付档位
   * @param  {String}  product_id 档位id
   * @param  {Function} callback 异步回掉
   * @return {Undefined}         无返回值，通过callback返回
   */
  startNativePaymentAction(productId, callback) {
    window.rechargeCallback = (data) => {
      callback && callback(data, 'startNativePaymentAction');
    };
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.startNativePaymentAction(JSON.stringify({
        product_id: productId
      }));
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'startNativePaymentAction',
        product_id: productId
      });
    } else {
      this.tryOpen(`startNativePaymentAction?product_id=${productId}`);
    }
  },

  /**
   * 打开礼物面板 ,并定位到礼物 (4.4.90添加)
   * @param  {String}  tabId 礼物面板tabid
   * @param  {String}  giftId 礼物id
   * @return {Undefined}  无返回值
   */
  showGiftPanelSelectGift(tabId, giftId) {
    const jsonString = JSON.stringify({
      tabId,
      giftId
    });
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.showGiftPanelSelectGift && KEWLObj.showGiftPanelSelectGift(jsonString);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'showGiftPanelSelectGift',
        jsonString
      });
    } else {
      this.tryOpen(`showGiftPanelSelectGift?jsonString=${jsonString}`);
    }
  },
  /**
   * 获取注册客户端im 消息回调
   * @param callback
   */
  registerSendDataToWeb(source, callback) {
    const fnName = asyncCallbackName((res) => {
      callback && callback(res);
      return true;
    });
    const data = {
      callback_source: source,
      callback: fnName
    };

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.registerSendDataToWeb && KEWLObj.registerSendDataToWeb(JSON.stringify(data));
    } else if (IsNewIosApp) {
      data.type = 'registerSendDataToWeb';
      this.iosPostMessage(data);
    }
  },

  /**
   * 调用客户端三方广告
   * @param  (String json)  参数json暂时没有用，为了后续扩展
   */
  showMobileAds(data) {
    const json = JSON.stringify(data);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.showMobileAds(json);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'showMobileAds',
        json
      });
    } else {
      this.tryOpen(`showMobileAds?json=${json}`);
    }
  },
  /**
   * 客户端设备震动
   */
  deviceShock() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.deviceShock();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'deviceShock'
      });
    } else {
      this.tryOpen('deviceShock');
    }
  },
  /**
   * 鲨鱼游戏新增更新最小化游戏icon
   * @param {*} data object
   * {
    "isShowEntrance": 1,  //是否显示航道入口 0不显示 1显示
    "isShowMsg": 1,       //航道入口是否显示右侧描述信息 0不显示 1显示
    "content": "Oning",   //航道入口倒计时区域显示内容
    "gameState": 1,       //游戏当前状态 0准备阶段 1游戏阶段
    "entryNum": 3,        //准备阶段使用，游戏当前参与人数
    "maxNum": 7,          //准备阶段使用，游戏当前最大参与人数
    "eliminateNum": 3,    //游戏阶段使用，游戏当前淘汰人数
    "surplusNum": 2,      //游戏阶段使用，游戏当前剩余人数
    }
   */
  gameChannelUpdate(data) {
    const json = JSON.stringify(data);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.gameChannelUpdate(json);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'gameChannelUpdate',
        json
      });
    } else {
      this.tryOpen(`gameChannelUpdate?json=${json}`);
    }
  },
  /**
   * 控制鲨鱼游戏webview
   * @param {*} data
   * {
      curType: "0" //关闭webveiw      "1" 最小化   ”2“ 最开最小化的webview
    }
   */
  webviewController(data) {
    const json = JSON.stringify(data);
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.webviewController(json);
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'webviewController',
        json
      });
    } else {
      this.tryOpen(`webviewController?json=${json}`);
    }
  },
  /**
   * 跳转意见反馈、工单
   * iOS从4.5.65开始支持；Android很早就有
   */
  openFeedback() {
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openFeedback && KEWLObj.openFeedback('');
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openFeedback'
      });
    } else {
      this.tryOpen('openFeedback');
    }
  },
  /**
   * 调起虚拟空间 4.5.75增加
   */
  openAvatarPage() {
    // 版本 过小 不执行
    if (!this.versionCompare('4.5.75')) {
      return;
    }
    if (IsKEWLApp && !IsIOS) {
      KEWLObj.openAvatarPage();
    } else if (IsNewIosApp) {
      this.iosPostMessage({
        type: 'openAvatarPage'
      });
    } else {
      this.tryOpen('openAvatarPage');
    }
  },
  /**
   * determine whether or not open successfully
   */
  // eslint-disable-next-line no-underscore-dangle
  _setTimeEvent(
    SUCCESS = () => { },
    FAILED = () => { },
    UNKNOWN = () => { },
    timeout = 1500
  ) {
    let haveChange = false;
    let property = 'hidden';
    let eventName = 'visibilitychange';
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      property = 'hidden';
      eventName = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      property = 'msHidden';
      eventName = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      property = 'webkitHidden';
      eventName = 'webkitvisibilitychange';
    }
    const pageChange = function (e) {
      haveChange = true;
      if (
        document[property]
        || e.hidden
        || document.visibilityState == 'hidden'
      ) {
        SUCCESS && SUCCESS();
        console.log('SUCCESS');
      } else {
        UNKNOWN && UNKNOWN();
        console.log('UNKNOWN');
      }
      document.removeEventListener(eventName, pageChange);
    };
    document.addEventListener(eventName, pageChange, false);
    setTimeout(() => {
      if (haveChange) {
        return;
      }
      document.removeEventListener(eventName, pageChange);
      if (!document.hidden && !haveChange) {
        FAILED && FAILED();
      } else {
        UNKNOWN && UNKNOWN();
      }
      haveChange = true;
    }, timeout);
  },
  /**
   * 通过product_id换取客户端对应大区的价格
   * @param productId
   * @param callback: {currency: string}
   */
  getCurrency(productId, callback) {
    const fnName = asyncCallbackName((res) => {
      callback && callback(res);
      return true;
    });
    const data = {
      product_id: productId,
      callback: fnName
    };

    if (IsKEWLApp && !IsIOS) {
      KEWLObj.getCurrency && KEWLObj.getCurrency(JSON.stringify(data));
    } else if (IsNewIosApp) {
      data.type = 'getCurrency';
      this.iosPostMessage(data);
    }
  }
};

Object.defineProperties(KEWLApp, {
  isKEWLApp: {
    get() {
      return IsKEWLApp;
    }
  }
});
if (IsKEWLApp) {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      KEWLApp.setTitle(document.title);
    },
    false
  );
}

window.Base64 = Base64;

// 避免zip 包使用 iframe 时候 报错
try {
  if (window.self == window.top) {
    window.KEWLApp = KEWLApp;
  } else {
    window.KEWLApp = window.top.KEWLApp ? window.top.KEWLApp : KEWLApp;
  }
} catch (error) {
  window.KEWLApp = KEWLApp;
}


export { Base64, KEWLApp };
